
import { Link } from "gatsby";
import React from "react"
import { Layout } from "../components/common";

import IndexStyles from  '../styles/index.module.css';


const IndexPage = () => {

    return (
        
        <Layout>
             <div>
                <div className={IndexStyles.container}>
                    <h3>Добре дошли в блога за къдрици на Curly!</h3>

                        

                </div>
            </div>
            
            <div className={IndexStyles.bannerSection}>
                <div className={IndexStyles.container}>
                    <div className={IndexStyles.bannerGroup}>
                        <div className={IndexStyles.bannerLeft}>
                            <div className={IndexStyles.bannerOverlay}>
                                <a href="/tag/maski-za-vulnista-i-kudrava-kosa">
                                    <img src="images/banner-1.jpg"></img>
                                    <div className={IndexStyles.banner2content}>Домашни маски</div>
                                </a>
                            </div>
                        </div>
                        <div className={IndexStyles.bannerRight}>
                            <div className={IndexStyles.bannerTopRight}>
                                <div className={IndexStyles.bannerOverlay}>
                                    <a href="/tag/co-wash">
                                        <img src="images/banner-2.jpg"/>
                                        <div className={IndexStyles.banner2content}>Измиване на косата / Co-wash</div>
                                    </a>
                                </div>
                            </div>
                            <div className={IndexStyles.bannerBottomWrapper}>
                                <div className={IndexStyles.bannerBottomLeft}>
                                    <div className={IndexStyles.bannerOverlay}>
                                        <a href="/tag/curly-girl-method">
                                            <img style={{ width: "100%" }} src="images/banner-3.jpg"></img>
                                            <div className={IndexStyles.banner2content}>Curly Girl Method</div>
                                        </a>
                                    </div>
                                        
                                    
                                </div>
                                <div className={IndexStyles.bannerBottomRight}>
                                <div className={IndexStyles.bannerOverlay}>
                                        <a href="/tag/izmivane-na-kosata">
                                        <img style={{ width: "100%" }} src="images/banner-4.jpg"></img>
                                            <div className={IndexStyles.banner2content}>Измиване на косата</div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={IndexStyles.container}>
                        
                <p style={{textAlign: "center", marginBottom: "0.5rem"}}>Създадохме Curly с много любов и грижа към къдриците.</p>
                <p style={{textAlign: "center", marginBottom: "0.5rem", fontWeight: "bold"}}>Curly е повече от магазин за продукти за коса.</p>
                <p style={{textAlign: "center", marginBottom: "0.5rem"}}>Подхранване, миене и стилизиране.</p>
                <p style={{textAlign: "center", marginBottom: "0.5rem", fontWeight: "bold"}}>Curly съветва. Curly разкрива. Curly запознава.</p>
                <p style={{textAlign: "center", marginBottom: "0.5rem"}}>С идеи, методи и брандове.</p>
                <p style={{textAlign: "center", marginBottom: "0.5rem", fontWeight: "bold"}}>Curly е философия за къдравелки.</p>
                <p style={{textAlign: "center", marginBottom: "1.5rem"}}>Помощник, консултант и приятел.</p>
                <p className={IndexStyles.intro} style={{fontStyle: "italic", fontSize: "1.1rem", fontWeight: "bold"}}>„Косата е корона, която трябва да се носи гордо и с усмивка.“</p> 
                <p style={{textAlign:"right", width: "80%"}}>- Екипът на Curly.bg</p>
            </div>
            <div className={IndexStyles.subscribe}>
                <div className={IndexStyles.container}>
                    <span>Открий над 500 продукта за нуждите на твоите къдрици</span>
                    <Link to="https://shop.curly.bg" className={IndexStyles.shopLink}>
                        <div class={IndexStyles.shopButton}>Разгледай магазина</div>
                    </Link>
                </div>
            </div>
        </Layout>
       
        
    )
}


export default IndexPage;